import firebase from "firebase";
import "firebase/firestore";
import firebaseConfig from "./firebase/constants";

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

class FirebaseService {
  async getDb() {
    // If the database connection is not already initialized, we do it here
    if (firebase.apps.length === 0) {
      firebase.initializeApp(firebaseConfig);
    }

    const db = await firebase.firestore();
    return db;
  }

  // subCollection = table, where = column on which to filter, value = value of the column
  async deleteSubCollection(subCollection, where, value) {
    const batch = firebase.firestore().batch();

    await firebase.firestore().collection(subCollection).where(where, '==', value).get().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        batch.delete(doc.ref);
      });
    });

    await batch.commit();
  }
}

export default new FirebaseService();
