import firebase from "firebase";
import "firebase/firestore";
import firebaseService from "./firebaseService";

class UserService {
    constructor() {
        firebaseService.getDb().then((db) => {
            this.db = db;
        });
    }

    async createUser(email, password) {
        await firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then((cred) => {
                this.createUserDocument(cred.user.uid);
                
                return cred;
            });
    }

    async editUser(userObj) {
        const user = await this.getUser();

        this.db.collection("Users")
            .doc(user.uid)
            .update({
                name: userObj.name,
                birthdate: userObj.birthdate,
                birthplace: userObj.birthplace,
                gender: userObj.gender,
                setupCompleted: true,
            });
    }

    async signInUser(email, password) {
        await firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then((user) => {
                console.log("signed in");
                return user;
            });
    }

    async signOutUser() {
        firebase
            .auth()
            .signOut()
            .then(
                function() {
                    console.log("Signed Out");
                },
                function(error) {
                    console.error("Sign Out Error", error);
                }
            );
    }
    
    async getUserData() {
        const user = await this.getUser();

        let doc = await this.db
            .collection("Users")
            .doc(user.uid)
            .get();
        return doc.data();
    }
    
    async getUser() {
        return await firebase.auth().currentUser;
        
    }
    
    authUser = () => {
        return new Promise((resolve) => {
            firebase.auth().onAuthStateChanged(
            (user) => {
                resolve(user);
            },
            (error) => {
                console.log(error);
            }
            );
        });
    };

    async createUserDocument(uid) {
        this.db.collection("Users")
          .doc(uid)
          .set({
            setupCompleted: false,
          });
    }
}

export default new UserService();