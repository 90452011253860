<template>
  <div>
    <ion-app id="darkToggler">
      <!-- <Toolbar/> -->
      <MenuLeft @theme-changed="loadSelectedTheme"/>
      <ion-vue-router id="main" />
    </ion-app>
    <ion-menu-controller></ion-menu-controller>
  </div>
</template>

<script>
import MenuLeft from "./components/MenuLeft";

export default {
  name: "app",
  data() {
    return {
    };
  },
  components: {
    MenuLeft,
  },
  mounted() {
    if (!localStorage.getItem('volume')) {
      localStorage.setItem('volume', 'on');
    }
    this.loadSelectedTheme();
   
  },
  methods: {
    loadSelectedTheme() {
      const selectedTheme = localStorage.getItem('selectedTheme');
    if (selectedTheme === 'ow') {
        import('./themes/variableswo.css');
    } else if (selectedTheme === 'bg') {
      import('./themes/variablesby.css');
    } else if (selectedTheme === 'pw') {
      import('./themes/variableswp.css');
    } else if (selectedTheme === 'bp') {
      import('./themes/variablesbp.css');
    } else {
  // Default theme if none selected
      import('./themes/variableswo.css');
    }

    document.querySelector('body').classList.add('custom-theme');
    }
  }
};
</script>
