const firebaseConfig = {
  apiKey: "AIzaSyBiHKXLlh8TSFfPZL1VGS4xpAPgrE5WPcE",
  authDomain: "kies-jij-maar.firebaseapp.com",
  databaseURL: "https://kies-jij-maar.firebaseio.com",
  projectId: "kies-jij-maar",
  storageBucket: "kies-jij-maar.appspot.com",
  messagingSenderId: "165799198979",
  appId: "1:165799198979:web:cd92bab1fe9f94253d6936",
};

export default firebaseConfig;