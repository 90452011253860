import Vue from "vue";
import { App } from "@capacitor/app";
import { IonicVueRouter } from "@ionic/vue";
import userService from "./services/userService";
const SharedList = () => import("@/views/SharedList");
const SharedSpinWheel = () => import("@/views/SharedSpinWheel")

Vue.use(IonicVueRouter);

const router = new IonicVueRouter({
  mode: "history",
  routes: [
    {
      // If you visit a random url you get redirected to the actual website
      path: "*",
      name: "Redirect",
      beforeEnter() {
        window.location.href = "http://kiesjijmaar.com";
      }
    },

    {
      path: "/shared/:listId",
      name: "sharedList",
      component: SharedList,
    },
    {
      path: '/sharedwheel/:listid/:user/:spinned',
      name: 'sharedwheel',
      component: SharedSpinWheel,
      props: true
    },

  ],
});

//check authentication before each route

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresUnauth = to.matched.some(
    (record) => record.meta.requiresUnauth
  );
  userService.authUser().then((user) => {
    if (requiresAuth && !user) {
      console.log("not authenticated");
      next("/checkin");
    } else if (requiresUnauth && user) {
      console.log("already authenticated");
      next("/");
    } else {
      next();
    }
  });
});

App.addListener("appUrlOpen", function(event) {
  // Example url: https://beerswift.app/tabs/tabs2
  // slug = /tabs/tabs2
  const slug = event.url.split(".com").pop();
  console.log("urlOpened");
  // We only push to the route if there is a slug present
  if (slug) {
    console.log(slug);
    router.push({
      path: slug,
    });
  }
});


export default router;
