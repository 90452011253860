import Vue from 'vue';
import App from './App.vue';
import { createPinia } from 'pinia';
import router from "./router";
import IonicVue from '@ionic/vue';
import '@ionic/core/css/core.css';
import '@ionic/core/css/ionic.bundle.css';
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueConfetti from 'vue-confetti'


Vue.config.productionTip = false;

Vue.use(IonicVue);
Vue.use(createPinia());
Vue.use(VueAxios, axios);
Vue.use(VueConfetti);

export const eventBus = new Vue();

new Vue({
  router,
  eventBus,
  render: h => h(App) 
}).$mount('#app');
