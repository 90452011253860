<template>
  <!-- Z-Index is 1000 to overlap the button -->
  <ion-menu
    side="start"
    menuId="first"
    content-id="main"
    swipeGesture="false"
    type="overlay"
    style="z-index: 1000"
  >
    <ion-content scroll-Y="false" class="ion-padding">
      <ion-icon name="close" class="closebtn" @click="closeStart" size="large"></ion-icon>

      <ion-list class="nav-buttons">
        <ion-item @click="routerHome">
          <ion-text> Home </ion-text>
        </ion-item>

        <ion-item @click="routerProfile">
          <ion-text> Profiel </ion-text>
        </ion-item>

        <ion-item @click="routerProfileLists">
          <ion-text > Mijn Lijsten </ion-text>
        </ion-item>


        <ion-item>
          <div class="themeicon" @click="themeOW">
          <ion-icon 
            :src="require(`../assets/icons/oranje-wit.svg`)" 
          >
          </ion-icon>
        </div>
        <div class="themeicon" @click="themeBG">
          <ion-icon
            :src="require(`../assets/icons/groen-zwart.svg`)"
          >
          </ion-icon>
        </div>
          <div class="themeicon" @click="themePW">
          <ion-icon
            :src="require(`../assets/icons/roze-wit.svg`)"
          >
          </ion-icon>
        </div>
          <div class="themeicon" @click="themeBP">
          <ion-icon
            :src="require(`../assets/icons/paars-zwart.svg`)"
          >
          </ion-icon>
        </div>
        </ion-item>


      </ion-list>
      <ion-item
        style="position: absolute; bottom: 10px; display: flex; align-items: center; justify-content: center"
      >
        <ion-icon @click="toggleVolume" :name="volume">
        </ion-icon>

        <ion-icon @click="visitSite" style="position: absolute; right:48px" name="information-circle-outline"></ion-icon>
      </ion-item>
    </ion-content>
  </ion-menu>
</template>

<script>
export default {
  name: "MenuLeft",
  data: () => ({
    volume: "volume-high",
    mode: {
      text: "Dark Mode",
      icon: "moon",
      type: true,
    },
  }),
  methods: {
    visitSite() {
      window.location.replace('http://kiesjijmaar.com')
    },
    toggleVolume() {
      if (this.volume == "volume-high") {
        this.volume = "volume-off";

        localStorage.setItem("volume", "off");
      } else {
        this.volume = "volume-high";
        localStorage.setItem("volume", "on");
      }
    },
    themeOW(){
    location.reload();
    localStorage.clear('selectedTheme');
    localStorage.setItem('selectedTheme', 'ow');
    this.$emit('theme-changed');
    },
    themeBG(){
      location.reload();
      localStorage.clear('selectedTheme');
      localStorage.setItem('selectedTheme', 'bg');
      this.$emit('theme-changed');
    },
    themePW(){
      location.reload();
      localStorage.clear('selectedTheme');
      localStorage.setItem('selectedTheme', 'pw');
      this.$emit('theme-changed');
    },
    themeBP(){
      location.reload();
      localStorage.clear('selectedTheme');
      localStorage.setItem('selectedTheme', 'bp');
      this.$emit('theme-changed');
    },
    routerProfile() {
      this.$router.push("/mykjm");
      this.closeStart();
    },
    routerProfileLists() {
      this.$router.push("/myLists");
      this.closeStart();
    },
    routerHome() {
      this.$router.push("/list");
      this.closeStart();
    },
    closeStart() {
      document.querySelector("ion-menu-controller").close("start");
    },
  },
  mounted(){
    if(localStorage.getItem('volume') == 'off'){
this.volume = 'volume-off'
    }
    else{
      this.volume = 'volume-high'
    }
  }
};
</script>

<style scoped>
.closebtn{
  position: absolute;
  right: 0.5rem;
  z-index: 1;
  font-size: larger;
}

ion-text {
  font-family: Nunito, sans-serif;
  font-weight: bolder;
}

.themeicon{
  padding: 5px;
}

ion-icon {
  font-size: 30px;
}

.no-ripple {
  --ripple-color: transparent;
}

ion-item {
  width: 100% !important;
  height: 50px !important;
  display: block;
  --border-style: hidden;
  font-size: 18px;
}
ion-content {
  padding: 0 !important;
}

</style>
